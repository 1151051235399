import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const complaintsApi = createApi({
  reducerPath: "complaintsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`,
  }),
  endpoints: (build) => ({
    createComplaint: build.mutation({
      query(formData) {
        return {
          url: "/users/complaints",
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: ["Game"],
    }),

    complaintTypes: build.query({
      query: () => ({
        url: "/complaint-types",
        method: "GET",
      }),
      providesTags: ["Complaint"],
    }),
  }),
});

export const { useCreateComplaintMutation, useComplaintTypesQuery } =
  complaintsApi;
