import React, { useEffect, useState } from "react";

const DetailNotificationPopUp = ({ isOpen, onClose, notificationDetails }) => {
  const [notificationData, setNotificationData] = useState({
    title: "",
    body: "",
    received_at: "",
    image_path: "",
  });

  useEffect(() => {
    if (notificationDetails) {
      const normalizedData = {
        title: notificationDetails.title || notificationDetails.text || "",
        body: notificationDetails.body || "",
        received_at:
          notificationDetails.date || notificationDetails.received_at || "",
        image_path: notificationDetails.image_path || "",
      };
      setNotificationData(normalizedData);
    }
  }, [notificationDetails]);

  const hasImage =
    notificationDetails?.image_path &&
    (Array.isArray(notificationDetails.image_path)
      ? notificationDetails?.image_path?.length > 0
      : true);

  const renderImages = () => {
    if (!hasImage) return null;

    const imagePaths = Array.isArray(notificationDetails.image_path)
      ? notificationDetails.image_path
      : [notificationDetails.image_path];

    return (
      <div
        className={`grid ${
          imagePaths.length > 1
            ? "grid-cols-2 gap-4 max-h-[500px] overflow-y-auto"
            : "grid-cols-1"
        } justify-center items-center`}
      >
        {imagePaths.map(
          (image, index) =>
            image && (
              <div key={index} className="flex justify-center">
                <img
                  src={`${process.env.REACT_APP_BACKEND_HOST}/api/${image}`}
                  alt="Njoftimi"
                  className="rounded-md max-w-full object-cover"
                />
              </div>
            )
        )}
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={onClose}
    >
      <div
        className={`bg-shadeWhite rounded-lg p-6 max-h-[800px] overflow-y-auto relative transition-all duration-300
          ${
            hasImage
              ? "w-full max-w-[672px] md:w-[672px]"
              : "w-full max-w-[400px] sm:w-[400px]"
          }`}
        onClick={(e) => e.stopPropagation()}
      >
        <button onClick={onClose} className="absolute top-4 right-4">
          ✕
        </button>

        <h2 className="text-lg font-semibold mb-4">
          Shikoni detajet e njoftimit
        </h2>

        {hasImage ? (
          <div className="flex flex-col md:flex-row gap-5">
            <div className="md:w-1/3 flex justify-center">{renderImages()}</div>
            <div className="md:w-2/3 w-full">
              <div className="bg-white p-4 rounded-lg shadow-md mb-4">
                <div className="flex items-center space-x-2 mb-2">
                  <img src="atkIcon.png" alt="icon" className="w-5 h-5" />
                  <span className="font-semibold text-gray-800">
                    {notificationData.title}
                  </span>
                  <span className="text-gray-500 text-sm">
                    {notificationData.received_at}
                  </span>
                </div>
                <p className="text-sm text-gray-700">{notificationData.body}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="bg-white p-4 rounded-lg shadow-md mb-4">
              <div className="flex items-center space-x-2 mb-2">
                <img src="atkIcon.png" alt="icon" className="w-5 h-5" />
                <span className="font-semibold text-gray-800">
                  {notificationData.title}
                </span>
                <span className="text-gray-500 text-sm">
                  {notificationData.received_at}
                </span>
              </div>
              <p className="text-sm text-gray-700">{notificationData.body}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailNotificationPopUp;
