import React, { useState } from "react";
import { useNotificationtDetailsQuery } from "../../store/services/notificationServices";
import DetailNotificationPopUp from "../DetailNotificationPopUp/DetailNotificationPopUp";

const NotificationTable = ({ data }) => {
  console.log("🚀 ~ NotificationTable ~ data:", data);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { data: notificationDetails, isLoading } = useNotificationtDetailsQuery(
    selectedNotificationId,
    { skip: !selectedNotificationId }
  );

  const handleEyeClick = (notification) => {
    setSelectedNotificationId(notification.notification_id);
    setIsPopupOpen(true);
  };

  const extractFirstSentence = (htmlString) => {
    const text = htmlString.replace(/<[^>]*>/g, "");
    const firstSentence = text.split(".")[0];
    return firstSentence ? `${firstSentence}` : text;
  };

  return (
    <>
      <div className="pl-4 sm:block hidden">
        <p className="text-xl font-medium pb-4">Njoftimet</p>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Titulli
                </th>
                <th scope="col" className="px-6 py-3">
                  Mesazhi
                </th>
                <th scope="col" className="px-6 py-3">
                  Data
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((notification, index) => (
                <tr
                  key={index}
                  onClick={() => handleEyeClick(notification)}
                  className={`cursor-pointer border-b border-gray-200 ${
                    notification.is_read === 1 ? "bg-gray-50" : "bg-white"
                  }`}
                >
                  <td className="px-6 py-4 font-medium text-gray-900">
                    {notification.title}
                  </td>
                  <td className="px-6 py-4">
                    {extractFirstSentence(notification.body)}
                  </td>
                  <td className="px-6 py-4">{notification.received_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isPopupOpen && (
        <DetailNotificationPopUp
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          isLoading={isLoading}
          notificationDetails={notificationDetails}
        />
      )}
    </>
  );
};

export default NotificationTable;
