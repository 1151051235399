import React, { useState, useEffect, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const CustomDropdown = ({
  options,
  selectedValue,
  setSelectedValue,
  buttonClassName = "",
  dropdownClassName = "",
  customStyles = "",
  selectedTextColor = "black",
  arrowColor = "black",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleSelect = (option, e) => {
    e.preventDefault();
    setSelectedValue(option.value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={toggleDropdown}
        className={`${buttonClassName} flex items-center gap-2 justify-between`}
      >
        <span
          style={{
            color: selectedValue ? selectedTextColor : "gray",
          }}
        >
          {options.find((opt) => opt.value === selectedValue)?.label ||
            "Selekto"}
        </span>

        <span className="ml-2">
          {isOpen ? (
            <FaChevronUp className={arrowColor} />
          ) : (
            <FaChevronDown className={arrowColor} />
          )}
        </span>
      </button>

      {isOpen && (
        <div className={dropdownClassName}>
          {options.map((option) => (
            <div
              key={option.value}
              onClick={(e) => handleSelect(option, e)}
              className={customStyles}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
