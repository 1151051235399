import React, { useState } from "react";
import {
  useDeleteReceiptMutation,
  useReceiptDetailsQuery,
} from "../../store/services/receiptServices";
import DetailReceiptPopUp from "../DetailReceiptPopUp/DetailReceiptPopUp";
import { toast } from "react-toastify";
import DeletePopUp from "../DeletePopUp/DeletePopUp";

const ReceiptTable = ({ data, title = "Faturat" }) => {
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseModal = () => {
    setSelectedReceipt(null);
    setModalOpen(false);
    setDeleteModal(false);
  };

  const [deleteReceipt] = useDeleteReceiptMutation();

  const { data: receiptDetails, isLoading } = useReceiptDetailsQuery(
    selectedReceipt
      ? { id: selectedReceipt.receipt_id, status: selectedReceipt.status }
      : {},
    { skip: !selectedReceipt }
  );

  const handleDeleteReceipt = async (id, e) => {
    e.preventDefault();
    try {
      setIsDeleting(true);
      await deleteReceipt({ id });
      toast.success("Fatura e arkivuar u fshie me sukses!");
      setDeleteModal(false);
      setSelectedReceipt(null);
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete receipt:", error);
      toast.error("Fatura nuk u fshie me sukses!");
    } finally {
      setIsDeleting(false);
    }
  };

  const visibleReceipts = data?.filter(
    (receipt) => receipt.NUI !== 0 && receipt.business_name !== "0"
  );

  return (
    <div className="pl-4 sm:block hidden mt-12">
      <p className="text-xl font-medium pb-4">{title}</p>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg hidden sm:block">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Numri Fiskal / NUI
              </th>
              <th scope="col" className="px-6 py-3">
                Faturat
              </th>
              <th scope="col" className="px-6 py-3">
                Data e ngarkimit
              </th>
              <th scope="col" className="px-6 py-3">
                Statusi
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(visibleReceipts) && visibleReceipts.length > 0 ? (
              visibleReceipts.map((receipt) => (
                <tr
                  key={receipt.receipt_id}
                  onClick={() => {
                    setSelectedReceipt(receipt);
                    setModalOpen(true);
                    setDeleteModal(false);
                  }}
                  className="cursor-pointer odd:bg-white  even:bg-gray-50 "
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    {receipt.NUI}
                  </th>
                  <td className="px-6 py-4">{receipt.business_name}</td>
                  <td className="px-6 py-4">
                    {new Date(receipt.created_at).toLocaleString()}
                  </td>
                  <td className="px-6 py-4">
                    <div
                      className={`flex gap-2 items-center border rounded-full w-max px-2 ${
                        receipt.status === "submitted"
                          ? "bg-lightRed border-darkRed"
                          : receipt.status === "approved"
                          ? "bg-lighterGrey border-darkGrey"
                          : receipt.status === "rejected"
                          ? "bg-lightRed border-darkRed"
                          : "bg-lightRed border-darkRed"
                      }`}
                    >
                      <div
                        className={`rounded-full w-2 h-2 ${
                          receipt.status === "submitted"
                            ? "bg-darkRed"
                            : receipt.status === "approved"
                            ? "bg-darkGrey"
                            : receipt.status === "rejected"
                            ? "bg-darkRed"
                            : "bg-darkRed"
                        }`}
                      ></div>
                      <p
                        className={`${
                          receipt.status === "submitted"
                            ? "text-darkRed"
                            : receipt.status === "approved"
                            ? "text-darkGrey"
                            : receipt.status === "rejected"
                            ? "text-darkRed"
                            : "text-darkRed"
                        }`}
                      >
                        {receipt.status === "submitted"
                          ? "Progres"
                          : receipt.status === "approved"
                          ? "Aprovuar"
                          : receipt.status === "rejected"
                          ? "Refuzuar"
                          : "Progres"}
                      </p>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  Nuk ka fatura të gjetura.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <DetailReceiptPopUp
        isOpen={modalOpen}
        onClose={handleCloseModal}
        isLoading={isLoading}
        receiptDetails={receiptDetails}
      />

      {deleteModal && (
        <DeletePopUp
          modalOpen={deleteModal}
          selectedReceipt={selectedReceipt}
          onClose={handleCloseModal}
          onDelete={(e) => handleDeleteReceipt(selectedReceipt.receipt_id, e)}
          isDeleting={isDeleting}
        />
      )}
    </div>
  );
};

export default ReceiptTable;
