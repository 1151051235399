import React, { useState, useEffect } from "react";

const DetailReceiptPopUp = ({ isOpen, onClose, isLoading, receiptDetails }) => {
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [newUserData, setNewUserData] = useState({
    business_name: "",
    address: "",
    vat_number: "",
    total_with_vat: "",
    vat_amount: "",
    total_without_vat: "",
    release_date: "",
    fiscal_device_serial_number: "",
  });

  useEffect(() => {
    if (receiptDetails) {
      setNewUserData({
        receipt_id: receiptDetails.receipt_id,
        business_name: receiptDetails.business_name || "",
        address: receiptDetails.address || "",
        fiscal_receipt_number:
          receiptDetails.fiscal_receipt_number || receiptDetails.NUI || "",
        total_with_vat: receiptDetails.total_with_vat || "",
        vat_amount: receiptDetails.vat_amount || "",
        total_without_vat: receiptDetails.total_without_vat || "",
        release_date: receiptDetails.release_date || "",
        fiscal_device_serial_number:
          receiptDetails.fiscal_device_serial_number || "",
      });
    }
  }, [receiptDetails]);

  const handleUserDataChange = (e) => {
    setNewUserData({ ...newUserData, [e.target.name]: e.target.value });
  };

  const fieldLabels = {
    business_name: "Emri i Biznesit",
    address: "Adresa e Biznesit",
    fiscal_receipt_number: "Numri fiskal / NUI",
    fiscal_device_serial_number: "Numri serik",
    total_with_vat: "Totali",
    vat_amount: "TVSH",
    total_without_vat: "Totali pa TVSH",
    release_date: "Data e leshimit",
  };

  const renderImages = () => {
    if (!receiptDetails || !receiptDetails.image_path) {
      return <p className="text-center">Nuk ka imazhe të faturave.</p>;
    }
    const imagePaths = Array.isArray(receiptDetails.image_path)
      ? receiptDetails.image_path
      : JSON.parse(receiptDetails.image_path);

    if (imagePaths.length === 0) {
      return <p className="text-center">Nuk ka imazhe të faturave.</p>;
    }

    return (
      <div
        className={`grid ${
          imagePaths.length > 1
            ? "flex flex-row gap-4 max-h-[500px] overflow-y-auto"
            : "grid-cols-1"
        } justify-center items-center`}
      >
        {imagePaths.map((image, index) => (
          <div key={index} className="flex justify-center">
            <img
              src={`${process.env.REACT_APP_BACKEND_HOST}/api/${image}`}
              alt={`Fatura ${index + 1}`}
              className="rounded-md max-w-full object-cover"
            />
          </div>
        ))}
      </div>
    );
  };

  return !isOpen ? null : (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 w-[90%] max-w-4xl max-h-[800px] overflow-y-auto relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button onClick={onClose} className="absolute top-4 right-4">
          ✕
        </button>

        <h2 className="text-lg font-semibold pb-3">Shikoni Kuponin</h2>

        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-1/3 flex flex-col items-center overflow-y-auto max-h-[500px]">
            {isLoading ? <p>Loading...</p> : renderImages()}
          </div>
          <div className="md:w-2/3">
            <form className="gap-4">
              {isLoading ? (
                <p>Loading...</p>
              ) : receiptDetails ? (
                <>
                  {Object.keys(fieldLabels).map((field) => (
                    <div key={field} className="pb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        {fieldLabels[field]}
                      </label>
                      <input
                        type="text"
                        name={field}
                        value={newUserData[field]}
                        onChange={handleUserDataChange}
                        className="mt-1 p-3 text-sm block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        disabled={isFormDisabled}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <p className="text-center"> Nuk ka fatura të gjetura.</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailReceiptPopUp;
