import { useEffect, useState } from "react";
import ReceiptTable from "../components/Receipt/ReceiptTable";
import Pagination from "../components/Pagination/Pagination";
import AddReceipt from "./AddReceipt";
import SideBar from "../components/SideBar/SideBar";
import { useUserReceiptsQuery } from "../store/services/receiptServices";
import NavBar from "../components/NavBar/NavBar";
import ReceiptBorder from "../components/Receipt/ReceiptBorder";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiUpload, FiCamera } from "react-icons/fi";
import ComplaintFormModal from "../components/ComplaintFormModal/ComplaintFormModal";

export default function Home() {
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [receiptsPerPage] = useState(10);
  const location = useLocation();
  const navigate = useNavigate();

  const { data: receiptsData } = useUserReceiptsQuery();

  const receipts = receiptsData?.receipts[0]?.data || [];

  const totalPages = Math.ceil(receipts.length / receiptsPerPage);

  const indexOfLastReceipt = currentPage * receiptsPerPage;
  const indexOfFirstReceipt = indexOfLastReceipt - receiptsPerPage;
  const currentReceipts = receipts.slice(
    indexOfFirstReceipt,
    indexOfLastReceipt
  );

  const [files, setFiles] = useState(null);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const validReceipts = receipts.filter((receipt) =>
    Object.values(receipt).some((value) => value !== null)
  );

  useEffect(() => {
    if (location.state?.toastMessage) {
      toast.success(location.state.toastMessage);

      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files?.length) return;
    setFiles(files);
    setShowUploadPopup(true);
    return files;
  };

  return (
    <div>
      {showSidebar && <SideBar />}
      <div className={`p-4 ${showSidebar ? "sm:ml-64" : ""}`}>
        <NavBar />
        <div className="sm:absolute sm:right-0 sm:top-24">
          <ComplaintFormModal />
        </div>
        <div className="flex gap-4 p-4 cursor-pointer">
          <div className="flex items-center justify-center bg-primary text-white text-md py-2 px-4 rounded w-[212px] h-[44px]">
            <FiUpload className="w-5 h-5" />
            <input
              type="file"
              accept="image/*"
              className="hidden"
              id="gallery-upload"
              onChange={handleFileUpload}
            />
            <label
              htmlFor="gallery-upload"
              className="cursor-pointer flex items-center justify-center w-full h-full"
            >
              Ngarko faturën
            </label>
          </div>

          <div className="block md:hidden flex bg-primary  items-center justify-center text-white text-md py-2 px-4 rounded w-[212px] h-[44px]">
            <FiCamera className="w-5 h-5" />
            <input
              type="file"
              accept="image/*"
              capture="environment"
              className="hidden"
              id="camera-upload"
              onChange={handleFileUpload}
            />
            <label
              htmlFor="camera-upload"
              className="cursor-pointer flex items-center justify-center w-full h-full"
            >
              Skeno faturën
            </label>
          </div>
        </div>

        <>
          {validReceipts.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-96">
              <img
                src="/emptyReceipe.png"
                alt="No Data"
                className="w-32 h-32 sm:w-48 sm:h-48"
              />
              <p className="text-lg text-center mt-4">
                Nuk keni fatura të ruajtura per ti shfaqur.
              </p>
            </div>
          ) : (
            <>
              <ReceiptTable
                data={currentReceipts}
                title="Të gjitha faturat"
                showDeleteIcon={true}
              />

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />

              <ReceiptBorder
                data={currentReceipts}
                title="Të gjitha faturat"
                showEditIcon={true}
              />
            </>
          )}
        </>
      </div>

      {showUploadPopup && (
        <AddReceipt files={files} onClose={() => setShowUploadPopup(false)} />
      )}

      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}
