import { useEffect, useState } from "react";
import InputField from "../components/auth/InputField";
import SubmitButton from "../components/SubmitButton";
import Button from "../components/Button";
import {
  useGetOwnAccountQuery,
  useUpdateOwnAccountMutation,
} from "../store/services/profileServices";
import findChangedFields from "../utils/compareObjects";
import getInitials from "../utils/getInitials";
import SideBar from "../components/SideBar/SideBar";
import NavBar from "../components/NavBar/NavBar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Profile() {
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [oldUserData, setOldUserData] = useState({
    name: "",
    phone_number: "",
    email: "",
    zip_code: "",
    birthdate: "",
  });
  const [newUserData, setNewUserData] = useState({
    name: "",
    phone_number: "",
    email: "",
    zip_code: "",
    birthdate: "",
  });

  const [passwords, setPasswords] = useState({
    new_password: "",
    confirm_password: "",
  });

  const { data } = useGetOwnAccountQuery();
  const [updateUser] = useUpdateOwnAccountMutation();

  useEffect(() => {
    if (data) {
      setOldUserData({
        ...data,
        birthdate: new Date(data.birthdate).toISOString().split("T")[0],
      });
      setNewUserData({
        ...data,
        birthdate: new Date(data.birthdate).toISOString().split("T")[0],
      });
    }
  }, [data]);

  const validatePassword = () => {
    if (
      passwords.new_password.length > 0 &&
      passwords.new_password.length < 6
    ) {
      alert("Password should contain at least 6 characters");
      return false;
    }
    if (passwords.new_password !== passwords.confirm_password) {
      alert("Passwords don't match");
      return false;
    }

    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const changedFields = findChangedFields(oldUserData, newUserData);

    let dataToBeSubmitted = Object.keys(changedFields).reduce((acc, key) => {
      acc[key] = changedFields[key].new;
      return acc;
    }, {});

    if (passwords.new_password.length > 0) {
      if (validatePassword()) {
        dataToBeSubmitted["password"] = passwords.new_password;
      }
    }

    if (Object.keys(dataToBeSubmitted).length < 1) {
      return;
    }

    try {
      console.log({ dataToBeSubmitted });
      const response = await updateUser({
        id: newUserData.id,
        data: dataToBeSubmitted,
      }).unwrap();
      console.log({ response });
      toast.success("Profili juaj u editua me sukses!");
    } catch (error) {
      console.error("Failed to update item:", error);
      toast.error("Editimi i profilit tuaj dështoi!");
    }
  };

  const handleNewPasswordChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handleUserDataChange = (e) => {
    setNewUserData({ ...newUserData, [e.target.name]: e.target.value });
  };

  const handleEditClick = () => {
    setIsFormDisabled(false);
  };

  const handleCancelEdit = () => {
    setIsFormDisabled(true);
    setNewUserData(oldUserData);
  };

  return (
    <div>
      <SideBar />
      <div className="sm:hidden block sm:px-0 px-4 sm:pt-0 pt-4">
        <NavBar />
      </div>

      <div className="p-4 flex justify-center items-center sm:ml-64 h-screen">
        <div className="w-[760px] flex flex-col gap-8 p-4">
          <div className="flex items-center sm:justify-center justify-start gap-3">
            <div className="w-14 h-14 rounded-full flex items-center justify-center bg-primary text-white text-xl">
              {oldUserData?.name ? getInitials(oldUserData.name) : ""}
            </div>
            <div>
              <div className="font-medium leading-6">{oldUserData.name}</div>
              <div className="text-sm text-gray-600">
                Përditësoni detajet tuaja personale
              </div>
            </div>
          </div>
          <div>
            <form onSubmit={handleFormSubmit} disabled>
              <div className="flex flex-col gap-8">
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="col-span-1 sm:col-span-2">
                    <InputField
                      type="text"
                      name="name"
                      value={newUserData.name}
                      placeholder="Emri & Mbiemri"
                      label="Emri & Mbiemri"
                      onChange={handleUserDataChange}
                      isDisabled={isFormDisabled}
                    />
                  </div>
                  <InputField
                    type="text"
                    name="phone_number"
                    value={newUserData.phone_number}
                    placeholder="Numri i telefonit"
                    label="Numri i telefonit"
                    onChange={handleUserDataChange}
                    isDisabled={isFormDisabled}
                    required={true}
                  />
                  <InputField
                    type="email"
                    name="email"
                    value={newUserData.email}
                    placeholder="Email"
                    label="Email"
                    onChange={handleUserDataChange}
                    isDisabled={isFormDisabled}
                    required={true}
                  />
                  <InputField
                    type="text"
                    name="zip_code"
                    value={newUserData.zip_code}
                    placeholder="Kodi postar"
                    label="Kodi postar"
                    onChange={handleUserDataChange}
                    isDisabled={isFormDisabled}
                    required={true}
                  />
                  <InputField
                    type="date"
                    name="birthdate"
                    value={newUserData.birthdate}
                    placeholder="Data e lindjes"
                    label="Data e lindjes"
                    onChange={handleUserDataChange}
                    isDisabled={isFormDisabled}
                    required={true}
                  />
                  <InputField
                    type="password"
                    name="new_password"
                    value={passwords.new_password}
                    placeholder="********"
                    label="Fjalëkalimi i ri"
                    onChange={handleNewPasswordChange}
                    isDisabled={isFormDisabled}
                    required={false}
                  />
                  <InputField
                    type="password"
                    name="confirm_password"
                    value={passwords.confirm_password}
                    placeholder="********"
                    label="Konfirmo fjalëkalimin e ri"
                    onChange={handleNewPasswordChange}
                    isDisabled={isFormDisabled}
                    required={false}
                  />
                  {/* <InputField type="date" name="birthdate" value={newUserData.birthdate} placeholder="Data e lindjes" label="Data e lindjes" onChange={handleUserDataChange} isDisabled={isFormDisabled} /> */}
                </div>
                {isFormDisabled ? (
                  <div>
                    <Button
                      value="Edito"
                      bgColor="bg-primary"
                      fontColor="text-white"
                      border="border border-primary"
                      onClick={handleEditClick}
                    />
                  </div>
                ) : (
                  <div className="flex gap-4">
                    <Button
                      value="Anulo"
                      bgColor="bg-[#E5E7EB]"
                      fontColor="text-black"
                      border="border border-[#D2D6DB]"
                      onClick={handleCancelEdit}
                    />
                    <SubmitButton value="Ruaj" />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}
