import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const NotificationPopUp = ({ notifications, onClose }) => {
  const popupRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div
      ref={popupRef}
      className="absolute top-24 sm:right-52 right-10 bg-white shadow-md border rounded-xl p-4 w-[350px] sm:w-[372px] z-50"
    >
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-lg">Njoftimet</h3>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
          &#10005;
        </button>
      </div>
      <div className="mt-2 max-h-96 overflow-y-auto">
        {notifications.length === 0 ? (
          <div className="flex items-center justify-center py-10">
            <p className="text-sm text-gray-500 font-semibold">
              Nuk keni njoftime!
            </p>
          </div>
        ) : (
          notifications.map((notification, index) => (
            <div
              key={index}
              className="flex items-center gap-2 py-2 cursor-pointer"
              onClick={() => navigate("/notifications")}
            >
              <img src="/atk.svg" alt="Notification" className="w-10 h-10" />
              <div>
                <p className="text-base font-normal text-black">
                  {notification.text}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default NotificationPopUp;
