export default function InputField({
  type,
  name,
  label,
  value,
  placeholder,
  onChange,
  isDisabled,
  required,
  error,
}) {
  return (
    <div className="flex flex-col gap-1">
      <label htmlFor={name} className="text-sm text-[#344054] font-medium">
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        className="w-full border border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
      />
      {error?.length > 0 &&
        error.map((error) => (
          <div className="text-sm text-red-600 font-medium">{error}</div>
        ))}
    </div>
  );
}
