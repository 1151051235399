import React from "react";

export default function CircularProgress({ percentage }) {
  const radius = 45;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (percentage / 100) * circumference;

  return (
    <div>
      <div className="relative w-24 h-24 flex items-center justify-center">
        <svg
          className="rotate-[-90deg]"
          width="100"
          height="100"
          viewBox="0 0 100 100"
        >
          {/* Background Circle */}
          <circle
            cx="50"
            cy="50"
            r={radius}
            stroke="#021766"
            strokeWidth="8"
            fill="transparent"
            opacity="0.3"
          />
          {/* Progress Circle */}
          <circle
            cx="50"
            cy="50"
            r={radius}
            stroke="#021766"
            strokeWidth="8"
            fill="transparent"
            strokeDasharray={circumference}
            strokeDashoffset={progress}
            strokeLinecap="round"
          />
        </svg>
        {/* Text in Center */}
        <div className="absolute text-primary font-bold text-lg">
          {percentage}%
        </div>
      </div>
    </div>
  );
}
