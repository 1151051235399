import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const receiptApi = createApi({
  reducerPath: "receiptApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`,
  }),
  endpoints: (build) => ({
    userReceipts: build.query({
      query: () => ({
        url: "/users/receipts",
        method: "GET",
      }),
      providesTags: ["Receipt"],
    }),

    userReceiptsDashboard: build.query({
      query: () => ({
        url: "/users/dashboard/receipts",
        method: "GET",
      }),
      providesTags: ["Receipt"],
    }),

    userReceiptsGraph: build.query({
      query: ({ status }) => ({
        url: `/users/dashboard/receipts/graph?status=${status}`,
        method: "GET",
      }),
      providesTags: ["Receipt"],
    }),

    archiveReceipts: build.query({
      query: () => ({
        url: "/users/approved-receipts",
        method: "GET",
      }),
      providesTags: ["Receipt"],
    }),

    receiptDetails: build.query({
      query: ({ id, status }) => ({
        url: `/users/receipts/${id}?status=${status}`,
        method: "GET",
      }),
      providesTags: ["Receipt"],
    }),

    updateReceipt: build.mutation({
      query({ id, data }) {
        return {
          url: `/submitted-receipts/${id}`,
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["Receipt"],
    }),

    createReceipt: build.mutation({
      query(formData) {
        return {
          url: "/submitted-receipts",
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: ["Receipt"],
    }),

    createReceiptSubmitted: build.mutation({
      query({ formData, id }) {
        return {
          url: `/pending-receipts/submit/${id}`,
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: ["Receipt"],
    }),

    deleteReceipt: build.mutation({
      query({ id }) {
        return {
          url: `/approved-receipts/${id}`,
          method: "PATCH",
        };
      },
      invalidatesTags: ["Receipt"],
    }),

    receiptProgress: build.query({
      query: () => ({
        url: "/users/dashboard/receipts/progress",
        method: "GET",
      }),
      providesTags: ["Receipt"],
    }),
  }),
});

export const {
  useUserReceiptsQuery,
  useUserReceiptsDashboardQuery,
  useArchiveReceiptsQuery,
  useReceiptDetailsQuery,
  useUpdateReceiptMutation,
  useCreateReceiptMutation,
  useCreateReceiptSubmittedMutation,
  useDeleteReceiptMutation,
  useUserReceiptsGraphQuery,
  useReceiptProgressQuery,
} = receiptApi;
