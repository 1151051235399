import { useEffect, useState } from "react";
import ButtonNew from "../components/Button/ButtonNew";
import {
  useCreateReceiptMutation,
  useUpdateReceiptMutation,
} from "../store/services/receiptServices";
import InputField from "../components/auth/InputField";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";

const processImageWithGoogleOCR = async (imageData) => {
  const apiKey = "AIzaSyCKVeQfFQQjzcWl2Gc8swvT8-rNA_7tsPg";
  const apiUrl = `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`;
  const body = JSON.stringify({
    requests: [
      {
        image: {
          content: imageData.split(",")[1],
        },
        features: [
          {
            type: "DOCUMENT_TEXT_DETECTION",
          },
        ],
      },
    ],
  });
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    const result = await response.json();
    return result.responses[0].fullTextAnnotation.text;
  } catch (error) {
    console.error("OCR Error with Google Vision API:", error);
    return null;
  }
};

const AddReceipt = ({
  files,
  onClose,
  editMode = false,
  existingReceipt = null,
}) => {
  const [uploadedImage, setUploadedImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [isResponsive, setIsResponsive] = useState(false);
  const [fields, setFields] = useState({
    fiscalNumber: existingReceipt?.fiscalNumber || "",
    nui: existingReceipt?.nui || "",
    fiscalDeviceNumber: existingReceipt?.fiscalDeviceNumber || "",
    vatAmount: existingReceipt?.vatAmount || "",
    totalWithoutVat: existingReceipt?.totalWithoutVat || "",
    total: existingReceipt?.total || "",
    businessName: existingReceipt?.businessName || "",
    businessAddress: existingReceipt?.businessAddress || "",
    isNonFiscal: existingReceipt?.isNonFiscal || "",
    releaseDate: existingReceipt?.releaseDate || "",
  });

  useEffect(() => {
    if (files) {
      handleFileUpload();
    }
  }, [files]);

  const [createReceipt, { isLoading }] = useCreateReceiptMutation();
  const [updateReceipt] = useUpdateReceiptMutation();

  const normalizeDecimalValue = (value) => {
    if (!value) return "";
    return value.replace(",", ".").trim();
  };

  useEffect(() => {
    const updateResponsive = () => {
      setIsResponsive(window.innerWidth <= 768);
    };

    updateResponsive();
    window.addEventListener("resize", updateResponsive);
    return () => {
      window.removeEventListener("resize", updateResponsive);
    };
  }, []);

  const parseFields = (text) => {
    const lines = text ? text.split("\n") : [];

    const isNonFiscal = !(
      text.includes("KUPONI FISKAL NR.") || text.includes("KUPON FISKAL NR.")
    );

    const parsedFields = {
      fiscalNumber: "",
      nui: "",
      fiscalDeviceNumber: "",
      vatAmount: "",
      totalWithoutVat: "",
      total: "",
      businessName: "",
      businessAddress: "",
      isNonFiscal: isNonFiscal
        ? "Kuponi eshte jo fiskal"
        : "Kuponi eshte fiskal",
      releaseDate: "",
    };

    const businessAcronyms = [
      "Rr\\.",
      "NR\\.",
      "N\\.",
      "p.n\\.",
      "pn.\\",
      "Adresa:",
      "Adresa",
      "Prishtinë",
      "PRISHTINE",
      "Pristina",
      "Prizren",
      "Pejë",
      "Gjakovë",
      "Mitrovicë",
      "Ferizaj",
      "Gjilan",
      "Podujevë",
      "Obiliq",
      "Lipjan",
      "Fushë Kosovë",
      "Suharekë",
      "Drenas",
      "Istog",
      "Deçan",
      "Malishevë",
      "Kamenicë",
      "Dragash",
      "Rahovec",
      "Viti",
      "Shtime",
      "Klinë",
      "Skenderaj",
      "Vushtrri",
      "Hani i Elezit",
      "Kaçanik",
      "Novobërdë",
      "Leposaviq",
      "Zubin Potok",
      "Zveçan",
      "Graçanicë",
      "Ranillug",
      "Partesh",
      "Kllokot",
      "Junik",
      "Mamushë",
      "Shtërpcë",
    ].join("|");

    const businessAbbreviations = [
      "N.P.K",
      "SH.P.K",
      "N.T.P",
      "N.T.P.SH",
      "N.SH",
      "L.L.C",
      "SH.A",
      "K.B",
      "O.J.Q",
      "S.H.K.A",
      "I.B",
      "U.N.J",
      "F.SH.M",
      "N.H",
      "P.T",
    ].join("|");

    const dateRegex = /\b\d{2}-\d{2}-\d{4}\b/;

    let foundDate = false;

    lines?.forEach((line, index) => {
      if (!isNonFiscal) {
        if (
          line.match(/(NUI\/NUMRI FISKAL|NR. FISKAL|NR.FISKAL|Numri Fiskal)/i)
        ) {
          const sameLineMatch = line.match(
            /(NUI\/NUMRI FISKAL|NR\. FISKAL|Numri Fiskal)[:\s]*(\d+)/i
          );
          if (sameLineMatch) {
            parsedFields.fiscalNumber = sameLineMatch[2];
          } else {
            for (let i = index + 1; i < lines.length; i++) {
              const nextLine = lines[i]?.trim();

              const nextLineMatch = nextLine.match(/^(\d{9,})$/);
              if (nextLineMatch) {
                parsedFields.fiscalNumber = nextLineMatch[1];
                break;
              }
            }
          }
        } else if (
          line.match(/TVSH (E=|C=|D=)/i) ||
          line.match(/TVSH (E|C|D)/i) ||
          line.match(/TVSH (E-|C-|D-)/i) ||
          line.match(/TUSH (E=|C=|D=)/i) ||
          line.match(/TUSH (E|C|D)/i) ||
          line.match(/TUSH (E-|C-|D-)/i)
        ) {
          let vatAmountFound = false;
          const sameLineMatch =
            line.match(/TVSH.*?(\d+[\.,]\d{2})$/) ||
            line.match(/TUSH.*?(\d+[\.,]\d{2})$/);
          if (sameLineMatch) {
            parsedFields.vatAmount = normalizeDecimalValue(sameLineMatch[1]);
            vatAmountFound = true;
          } else {
            const searchRange = 10;
            for (let i = 1; i <= searchRange; i++) {
              const lineToCheck = lines[index + i]?.trim();
              if (!lineToCheck) break;
              const vatMatch = lineToCheck.match(/^(\d+[\.,]\d{2})$/);
              if (vatMatch) {
                const vatCandidate = normalizeDecimalValue(vatMatch[1]);
                const isDuplicate = vatCandidate === parsedFields.total;
                if (!isDuplicate) {
                  parsedFields.vatAmount = vatCandidate;
                  vatAmountFound = true;
                  break;
                }
              }
              if (
                lineToCheck.match(
                  /TOTALI|PARA TE GATSHME|ARTIKUJ|ORA|DATA|FISKAL/i
                )
              ) {
                break;
              }
            }
          }
        } else if (line.match(/TOT\. PA/i)) {
          let totalWithoutVatFound = false;
          const sameLineMatch = line.match(/TOT\. PA TVSH.*?(\d+[\.,]\d{2})$/i);
          if (sameLineMatch) {
            parsedFields.totalWithoutVat = normalizeDecimalValue(
              sameLineMatch[1]
            );
            totalWithoutVatFound = true;
          } else {
            const searchRange = 10;
            for (let i = 1; i <= searchRange; i++) {
              const lineToCheck = lines[index + i]?.trim();
              if (!lineToCheck) break;
              const totalMatch = lineToCheck.match(/^(\d+[\.,]\d{2})$/);
              if (totalMatch) {
                const totalCandidate = normalizeDecimalValue(totalMatch[1]);

                if (!totalWithoutVatFound) {
                  parsedFields.totalWithoutVat = totalCandidate;
                  totalWithoutVatFound = true;
                  break;
                }
              }
              if (
                lineToCheck.match(
                  /TOTALI|PARA TE GATSHME|TVSH|ARTIKUJ|ORA|DATA|FISKAL/i
                )
              ) {
                break;
              }
            }
          }
        } else if (line.includes("TOTALI NE EURO")) {
          const sameLineMatch = line.match(/TOTALI NE EURO.*?(\d+[\.,]\d{2})/);
          if (sameLineMatch) {
            parsedFields.total = normalizeDecimalValue(sameLineMatch[1]);
          } else {
            for (let i = index + 1; i < lines.length; i++) {
              const nearbyLine = lines[i]?.trim();
              const nearbyMatch = nearbyLine.match(/^(\d+[\.,]\d{2})$/);
              if (nearbyMatch) {
                parsedFields.total = normalizeDecimalValue(nearbyMatch[1]);
                break;
              }
            }
          }
        } else if (!parsedFields.businessName && line.match(/"([^"]*)"/g)) {
          parsedFields.businessName = line.trim();
        } else if (
          !parsedFields.businessName &&
          line.match(new RegExp(businessAbbreviations, "i"))
        ) {
          parsedFields.businessName = line.trim();
        } else if (
          !parsedFields.businessAddress &&
          line.match(new RegExp(businessAcronyms, "i"))
        ) {
          parsedFields.businessAddress = line.trim();
        }

        for (let index = 0; index < lines.length; index++) {
          const line = lines[index]?.trim();

          if (line.match(/NR\. SERIK/i)) {
            const sameLineMatch = line.match(dateRegex);

            if (sameLineMatch) {
              parsedFields.releaseDate = sameLineMatch[0];
              foundDate = true;
            } else {
              for (
                let i = index + 1;
                i < Math.min(index + 4, lines.length);
                i++
              ) {
                const nextLine = lines[i]?.trim();
                const nextLineMatch = nextLine.match(dateRegex);
                if (nextLineMatch) {
                  parsedFields.releaseDate = nextLineMatch[0];
                  foundDate = true;
                  break;
                }
              }
            }
          }

          if (line.includes("DATA")) {
            const dateMatchAfterData = line.match(dateRegex);
            if (dateMatchAfterData) {
              parsedFields.releaseDate = dateMatchAfterData[0];
              foundDate = true;
            }
          }

          for (let index = 0; index < lines.length; index++) {
            const line = lines[index]?.trim();

            const fiscalDeviceMatch = line.match(/^(SG|S6|EN|GK|6K)\d+(?!M1)$/);

            if (fiscalDeviceMatch) {
              parsedFields.fiscalDeviceNumber = fiscalDeviceMatch[0];
              break;
            }
          }
        }
      }
    });

    if (isNonFiscal) {
      if (!parsedFields.fiscalNumber) {
        parsedFields.fiscalNumber = "0";
      }
      if (!parsedFields.fiscalDeviceNumber) {
        parsedFields.fiscalDeviceNumber = "0";
      }
      if (!parsedFields.vatAmount) {
        parsedFields.vatAmount = "0";
      }
      if (!parsedFields.totalWithoutVat) {
        parsedFields.totalWithoutVat = "0";
      }
      if (!parsedFields.total) {
        parsedFields.total = "0";
      }
      if (!parsedFields.businessName) {
        parsedFields.businessName = "0";
      }
      if (!parsedFields.businessAddress) {
        parsedFields.businessAddress = "0";
      }
    }

    if (!parsedFields.isNonFiscal) {
      parsedFields.isNonFiscal = isNonFiscal
        ? "Kuponi eshte jo fiskal"
        : "Kuponi eshte fiskal";
    }

    if (!parsedFields.releaseDate) {
      parsedFields.releaseDate = "0";
    }

    return parsedFields;
  };

  useEffect(() => {
    const previews = uploadedImage.map((file) => URL.createObjectURL(file));
    setImagePreview(previews);

    return () => {
      previews.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [uploadedImage]);

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 800;
          const maxHeight = 800;
          let width = img.width;
          let height = img.height;

          if (width > height && width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          } else if (height > width && height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.8
          );
        };
        img.onerror = (err) => reject(err);
        img.src = e.target.result;
      };
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async () => {
    if (!files?.length) return;

    setLoading(true);

    const newUploadedImages = [];
    const newImagePreviews = [];

    for (const file of files) {
      if (
        newUploadedImages.some(
          (existingFile) => existingFile.name === file.name
        )
      ) {
        continue;
      }

      try {
        let processedFile = file;

        if (file.type === "image/png") {
          const compressedBlob = await compressImage(file);
          processedFile = new File(
            [compressedBlob],
            file.name.replace(/\.png$/, ".jpg"),
            { type: "image/jpeg" }
          );
        }

        newUploadedImages.push(processedFile);
        newImagePreviews.push(URL.createObjectURL(processedFile));

        const reader = new FileReader();
        reader.onload = async (e) => {
          const imageData = e.target.result;
          const extractedText = await processImageWithGoogleOCR(imageData);
          if (extractedText) {
            const parsedFields = parseFields(extractedText);
            setFields((prevFields) => ({
              ...prevFields,
              ...Object.fromEntries(
                Object.entries(parsedFields).filter(
                  ([key, value]) => value && !prevFields[key]
                )
              ),
            }));
          } else {
            toast.error("Failed to process the image. Please try again.");
          }
          setLoading(false);
        };
        reader.readAsDataURL(processedFile);
      } catch (error) {
        console.error("File upload error:", error);
        toast.error("Failed to upload and process the image.");
        setLoading(false);
      }
    }

    setUploadedImage((prev) => [...(prev || []), ...newUploadedImages]);
    setImagePreview((prev) => [...prev, ...newImagePreviews]);
  };

  const handleRemoveImage = (index) => {
    setUploadedImage((prev) => prev.filter((_, i) => i !== index));
    setImagePreview((prev) => prev.filter((_, i) => i !== index));
  };
  const handleSave = async (e) => {
    e.preventDefault();

    const {
      fiscalNumber,
      vatAmount,
      totalWithoutVat,
      fiscalDeviceNumber,
      total,
      businessName,
      businessAddress,
      releaseDate,
      isNonFiscal,
    } = fields;

    if (
      !businessName ||
      !fiscalDeviceNumber ||
      !businessAddress ||
      !total ||
      !fiscalNumber ||
      !vatAmount ||
      !totalWithoutVat ||
      !releaseDate ||
      !isNonFiscal
    ) {
      toast.error("Ju lutemi plotësoni fushat e kërkuara.", {
        autoClose: 5000,
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("fiscal_receipt_number", fiscalNumber || "");
      formData.append("fiscal_device_serial_number", fiscalDeviceNumber || "");
      formData.append("NUI", fiscalNumber || "");
      formData.append("vat_amount", vatAmount || "");
      formData.append("total_without_vat", totalWithoutVat || "");
      formData.append("total_with_vat", total);
      formData.append("business_name", businessName);
      formData.append("address", businessAddress);
      formData.append("release_date", releaseDate);
      formData.append(
        "is_non_fiscal",
        isNonFiscal === "Kuponi eshte jo fiskal" ? "true" : "false"
      );

      if (uploadedImage.length) {
        if (uploadedImage.some((file) => file.size > 5 * 1024 * 1024)) {
          toast.error("Madhësia e imazhit nuk mund të kalojë 5MB.", {
            autoClose: 5000,
          });
          return;
        }

        uploadedImage.forEach((file) => {
          formData.append("images", file);
        });
      }

      let response;
      if (fields.receiptId) {
        response = await updateReceipt({
          receiptId: fields.receiptId,
          formData,
        }).unwrap();
        toast.success("Kuponi fiskal u përditësua me sukses!", {
          autoClose: 3000,
        });
      } else {
        response = await createReceipt(formData).unwrap();

        if (isNonFiscal === "Kuponi eshte jo fiskal") {
          toast.error("Ju nuk mund të luani me kuponet jo-fiskale.", {
            autoClose: 3000,
          });
        } else {
          toast.success("Kuponi fiskal u ruajt me sukses!", {
            autoClose: 3000,
          });
        }
      }

      console.log("API Response:", response);

      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error) {
      if (error?.data?.message) {
        toast.error(`Gabim: ${error.data.message}`, {
          autoClose: 3000,
        });
      } else {
        toast.error("Ruajtja e kuponit fiskal deshtoi. Provo përsëri.", {
          autoClose: 3000,
        });
      }
      console.error("Error saving receipt:", error);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg sm:p-6 p-4 sm:w-[90%] w-[80%]  max-w-4xl max-h-[700px] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center pb-2 mb-4">
          <h2 className="text-lg font-bold">
            {uploadedImage ? "Detajet e fatures" : "Ngarko nje fature"}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-800"
          >
            ✕
          </button>
        </div>
        {uploadedImage?.length ? (
          <div className="flex sm:flex-row flex-col gap-8">
            <div className="sm:w-1/3 w-full">
              <p className="text-gray-500 text-sm mb-2">Dokumentet:</p>
              <div className="flex flex-col gap-4 overflow-y-auto max-h-[500px]">
                {imagePreview.map((preview, index) => (
                  <div key={index} className="relative border rounded p-2">
                    <img
                      src={preview}
                      alt={`Fatura ${index + 1}`}
                      className="w-full h-full object-contain"
                    />
                    <button
                      className="absolute top-0 right-0 bg-lighterGrey text-black rounded-full w-8 h-8"
                      onClick={() => handleRemoveImage(index)}
                    >
                      ✕
                    </button>
                  </div>
                ))}
              </div>
              <label
                htmlFor="file-upload"
                className="mt-4 bg-gray-200 text-sm py-2 px-4 rounded hover:bg-gray-300 cursor-pointer block text-center"
              >
                Shto një tjetër
              </label>
              <input
                type="file"
                accept="image/*"
                multiple
                capture="environment"
                className="hidden"
                id="file-upload"
                onChange={handleFileUpload}
              />
            </div>
            <div className="sm:w-2/3 w-full">
              {loading ? (
                <div className="flex items-center justify-center h-full">
                  <ClipLoader size={50} color="#021766" />
                </div>
              ) : (
                <form className="flex flex-col gap-4">
                  <input type="text" value={fields.isNonFiscal} readOnly />
                  <InputField
                    label="Emri i Biznesit"
                    id="businessName"
                    value={fields.businessName}
                    onChange={(e) =>
                      setFields((prev) => ({
                        ...prev,
                        businessName: e.target.value,
                      }))
                    }
                  />
                  <InputField
                    label="Adresa e Biznesit"
                    id="businessAddress"
                    value={fields.businessAddress}
                    onChange={(e) =>
                      setFields((prev) => ({
                        ...prev,
                        businessAddress: e.target.value,
                      }))
                    }
                  />
                  <InputField
                    label="Numri fiskal / NUI"
                    value={fields.fiscalNumber}
                    onChange={(e) =>
                      setFields((prev) => ({
                        ...prev,
                        fiscalNumber: e.target.value,
                      }))
                    }
                  />
                  <InputField
                    label="Numri serik"
                    value={fields.fiscalDeviceNumber}
                    onChange={(e) =>
                      setFields((prev) => ({
                        ...prev,
                        fiscalDeviceNumber: e.target.value,
                      }))
                    }
                  />
                  <InputField
                    label="Totali"
                    id="total"
                    value={fields.total}
                    onChange={(e) =>
                      setFields((prev) => ({
                        ...prev,
                        total: e.target.value,
                      }))
                    }
                  />
                  <InputField
                    label="TVSH"
                    value={fields.vatAmount}
                    onChange={(e) =>
                      setFields((prev) => ({
                        ...prev,
                        vatAmount: e.target.value,
                      }))
                    }
                  />
                  <InputField
                    label="Totali pa TVSH"
                    id="totalWithoutVat"
                    value={fields.totalWithoutVat}
                    onChange={(e) =>
                      setFields((prev) => ({
                        ...prev,
                        totalWithoutVat: e.target.value,
                      }))
                    }
                  />
                  <InputField
                    label="Data e lëshimit"
                    id="releaseDate"
                    value={fields.releaseDate}
                    onChange={(e) =>
                      setFields((prev) => ({
                        ...prev,
                        releaseDate: e.target.value,
                      }))
                    }
                  />
                  {/* <InputField
                    label="Data e lëshimit"
                    id="releaseDate"
                    value={fields.releaseDate}
                    onChange={(e) => {
                      if (fields.isNonFiscal === "Kuponi eshte jo fiskal") {
                        setFields((prev) => ({
                          ...prev,
                          releaseDate: e.target.value,
                        }));
                      }
                    }}
                    isDisabled={fields.isNonFiscal !== "Kuponi eshte jo fiskal"}
                  /> */}

                  <div className="flex gap-3">
                    <ButtonNew
                      text={editMode ? "Edito Faturen" : "Ruaj"}
                      color="bg-primary"
                      textStyles="text-white text-nowrap"
                      width="w-1/3"
                      height="h-[44px]"
                      onClick={handleSave}
                      isLoading={isLoading}
                    />
                    <ButtonNew
                      text="Anulo"
                      color="bg-lightGrey"
                      textStyles="text-black"
                      width="w-1/3"
                      height="h-[44px]"
                      onClick={onClose}
                    />
                  </div>
                </form>
              )}
            </div>
            <ToastContainer position="top-right" autoClose={3000} />
          </div>
        ) : (
          <div className="flex gap-8 items-center flex-wrap">
            <div className="flex-1">
              <div className="flex flex-col items-center gap-4 p-5 text-center border-dashed border rounded">
                <div className="bg-lighterGrey rounded-full flex items-center justify-center w-14 h-14 mx-auto">
                  <img src="/upload.png" alt="Upload" className="w-8 h-8" />
                </div>
                <p className="text-primary font-semibold text-sm mt-4">
                  Kliko per te krijuar nje fature
                </p>
                <p className="text-greyColor text-xs mb-4">
                  SVG, PNG, JPG, ose GIF (max. 800x400px)
                </p>
                <div className="flex gap-4 flex-wrap justify-center">
                  <div className="w-full sm:w-auto">
                    {/* foto live */}
                    <input
                      type="file"
                      accept="image/*"
                      capture="environment"
                      className="hidden"
                      id="camera-upload"
                      onChange={handleFileUpload}
                    />
                    <label
                      htmlFor="camera-upload"
                      className="bg-primary text-white text-sm py-2 px-4 rounded cursor-pointer mt-4 w-full sm:w-auto"
                    >
                      Ngarko nje fature
                    </label>
                  </div>
                  <div className="w-full sm:w-auto block md:hidden">
                    {/* foto prej galleris */}
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      id="gallery-upload"
                      onChange={handleFileUpload}
                    />
                    <label
                      htmlFor="gallery-upload"
                      className="bg-primary text-white text-sm py-2 px-4 rounded cursor-pointer mt-4 w-full sm:w-auto"
                    >
                      Zgjedh nje fature nga galeria
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddReceipt;
