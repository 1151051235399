import { useGetBanksQuery } from "../../store/services/bankServices";
import InputField from "./InputField";

export const BankInfo = ({ bankNumber, bankId, onChange, error }) => {

    const { data: banks, isLoading: isBanksLoading } = useGetBanksQuery()

    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1">
                <label htmlFor="phone_prefix" className="text-sm text-[#344054] font-medium">Numri i bankës</label>
                <div className="flex items-center gap-2">
                    <div
                        name="bank_country"
                        className="w-1/4 border border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500 bg-white"
                        disabled
                    >
                        <div className="flex gap-2 items-center">
                            <img src="/kosovo_flag.ico" className="w-4" />
                            <div>XK</div>
                        </div>
                    </div>
                    <input
                        name="bank_account_number"
                        type="number"
                        className="w-3/4 border border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
                        placeholder="******************"
                        value={bankNumber}
                        onChange={(e) => {
                            if (e.target.value.length > 16) {
                                return;
                            }
                            onChange(e)
                        }}
                    />
                </div>
                {error.bank_account_number && (
                    <div className="text-sm text-red-600 font-medium">{error.bank_account_number}</div>
                )}
            </div>
            <div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="bank_id" className="text-sm text-[#344054] font-medium">Emri i bankës</label>
                    <select name="bank_id" id="bank_id" className="w-full border border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500" defaultValue={bankId} onChange={onChange}>
                        <option value="" disabled>Vendos emrin e bankës</option>
                        {banks?.map(bank => (
                            <option value={bank.id}>{bank.name}</option>
                        ))}
                    </select>
                    {error.bank_name && (
                        <div className="text-sm text-red-600 font-medium">{error.bank_name}</div>
                    )}
                </div>
            </div>
        </div>
    )
}