import { useState } from "react";
import LogoHeader from "../components/auth/LogoHeader";
import InputField from "../components/auth/InputField";
import SubmitButton from "../components/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../store/services/authServices";
import { ClipLoader } from "react-spinners";
import AuthWrapper from "../components/auth/AuthWrapper";
import { BirthdateInput } from "../components/auth/BirthdateInput";
import { PhoneNumberInput } from "../components/auth/PhoneNumberInput";
import { BankInfo } from "../components/auth/BankInfo";
import { TermsOfConditions } from "../components/TermsOfConditions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Register() {
  const [userData, setUserData] = useState({
    personal_number: "",
    first_name: "",
    last_name: "",
    phone_prefix: "+383",
    phone_dialling_code: "",
    phone_number_1: "",
    phone_number_2: "",
    bank_id: "",
    bank_account_number: "",
    birth_day: "",
    birth_month: "",
    birth_year: "",
    email: "",
    password: "",
    birthdate: new Date().toISOString().split("T")[0],
    confirm_password: "",
    zip_code: "",
  });

  const [isNavigating, setIsNavigating] = useState(false);
  const [registerUser, { isLoading }] = useRegisterMutation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isTermsOfConditionsOpen, setIsTermsOfConditionsOpen] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleUserDataChange = (e) => {
    if (errors[e.target.name]) {
      if (e.target.value.length > 0) {
        let updatedErrors = { ...errors };
        delete updatedErrors[e.target.name];
        setErrors(updatedErrors);
      }
    } else if (e.target.name.includes("phone")) {
      if (e.target.value.length > 0) {
        let updatedErrors = { ...errors };
        delete updatedErrors["phone_number"];
        setErrors(updatedErrors);
      }
    } else if (e.target.name.includes("birth")) {
      if (e.target.value.length > 0) {
        let updatedErrors = { ...errors };
        delete updatedErrors["birth_date"];
        setErrors(updatedErrors);
      }
    }
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const formValidated = () => {
    let errors = {};
    if (!userData.personal_number)
      errors.personal_number = "Numri personal është i detyrueshëm";
    else if (userData.personal_number.length !== 10)
      errors.personal_number = "Numri personal duhet të jetë 10 shifror";
    if (!userData.first_name) errors.first_name = "Emri është i detyrueshëm";
    if (!userData.last_name) errors.last_name = "Mbiemri është i detyrueshëm";
    if (
      !userData.phone_dialling_code ||
      !userData.phone_number_1 ||
      !userData.phone_number_2
    )
      errors.phone_number = "Numri i telefonit është i detyrueshëm";
    if (!userData.email) errors.email = "Email është i detyrueshëm";
    else if (!emailRegex.test(userData.email))
      errors.email = "Ju lutem shkruani një email valid";
    if (!userData.birth_day || !userData.birth_month || !userData.birth_year)
      errors.birth_date = "Data e lindjes është e detyrueshme";
    if (userData.password.length < 8)
      errors.password = "Fjalëkalimi duhet të permbajë së paku 8 karaktere";
    else if (userData.password !== userData.confirm_password)
      errors.password = "Fjalëkalimet nuk përshtaten";
    if (!userData.zip_code) errors.zip_code = "Kodi postar është i detyrueshëm";
    if (!userData.bank_account_number)
      errors.bank_account_number = "Numri i bankës është i detyrueshëm";
    else if (userData.bank_account_number.length < 16)
      errors.bank_account_number =
        "Numri i bankës duhet të përmbajë 16 karaktere";
    if (!userData.bank_id)
      errors.bank_name = "Emri i bankës është i detyrueshëm";
    if (!acceptedTerms)
      errors.terms_of_conditions =
        "Nuk mund të vazhdoni nëse nuk pajtoheni me kushtet e përdorimit";

    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      return false;
    }

    return true;
  };

  const handleTermsOfConditionsChange = () => {
    setIsTermsOfConditionsOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formValidated()) {
      const data = {
        personal_number: userData.personal_number,
        name: `${userData.first_name} ${userData.last_name}`,
        email: userData.email,
        password: userData.password,
        phone_number: [
          userData.phone_prefix,
          userData.phone_dialling_code,
          userData.phone_number_1,
          userData.phone_number_2,
        ].join(""),
        birthdate: [
          userData.birth_year,
          userData.birth_month,
          userData.birth_day,
        ].join("-"),
        zip_code: userData.zip_code,
        bank_account_number: userData.bank_account_number,
        bank_id: userData.bank_id,
      };
      console.log({ data });
      try {
        const result = await registerUser(data).unwrap();
        console.log("User created:", result);
        setIsNavigating(true);
        toast.success("U regjistruat me sukses!", {
          autoClose: 1000,
          onClose: () => navigate("/login"),
        });
      } catch (error) {
        toast.error(error.data.message);
        console.error("Failed to create item:", error.data.message);
      }
    } else {
      toast.error("Ju lutem verifikoni të dhënat tuaja!");
      console.log("test");
    }
  };

  return (
    <AuthWrapper>
      {isNavigating && (
        <div className="fixed bg-white text-primary top-0 left-0 w-full h-screen flex items-center justify-center">
          <ClipLoader size={40} color="var(--primary-color)" />
        </div>
      )}
      <ToastContainer position="top-right" autoClose={3000} />
      <TermsOfConditions
        isOpen={isTermsOfConditionsOpen}
        handleChange={handleTermsOfConditionsChange}
      />
      <LogoHeader />
      <div className="text-center flex flex-col gap-2">
        <h1 className="text-[30px] font-semibold">Regjistrohu</h1>
        <h3 className="text-gray-600">
          Shkruani të dhënat e kërkuara më poshtë
        </h3>
      </div>
      <div className="my-4 mt-8 z-20">
        <form onSubmit={handleFormSubmit}>
          <div className="flex flex-col gap-6">
            <InputField
              type="number"
              name="personal_number"
              label="Numri personal"
              value={userData.personal_number}
              placeholder="Shkruani numrin tuaj personal"
              onChange={handleUserDataChange}
              required={true}
              error={errors?.personal_number ? [errors?.personal_number] : []}
            />
            <InputField
              type="text"
              name="first_name"
              label="Emri"
              value={userData.first_name}
              placeholder="Shkruani emrin tuaj"
              onChange={handleUserDataChange}
              required={true}
              error={errors?.first_name ? [errors?.first_name] : []}
            />
            <InputField
              type="text"
              name="last_name"
              label="Mbiemri"
              value={userData.last_name}
              placeholder="Shkruani mbiemrin tuaj"
              onChange={handleUserDataChange}
              required={true}
              error={errors?.last_name ? [errors?.last_name] : []}
            />
            <PhoneNumberInput
              prefix={userData.phone_prefix}
              dialling={userData.phone_dialling_code}
              phone1={userData.phone_number_1}
              phone2={userData.phone_number_2}
              onChange={handleUserDataChange}
              error={errors?.phone_number ? [errors.phone_number] : []}
            />
            <InputField
              type="text"
              name="email"
              label="Email"
              value={userData.email}
              placeholder="Shkruani emailin tuaj"
              onChange={handleUserDataChange}
              required={true}
              error={[...(errors?.email ? [errors.email] : [])]}
            />
            <BirthdateInput
              day={userData.birth_day}
              month={userData.birth_month}
              year={userData.birth_year}
              onChange={handleUserDataChange}
              error={errors.birth_date ? [errors.birth_date] : []}
            />
            <InputField
              type="password"
              name="password"
              label="Fjalëkalimi"
              value={userData.password}
              placeholder="••••••••"
              onChange={handleUserDataChange}
              required={true}
              error={[...(errors?.password ? [errors.password] : [])]}
            />
            <InputField
              type="password"
              name="confirm_password"
              label="Konfirmo fjalëkalimin"
              value={userData.confirm_password}
              placeholder="••••••••"
              onChange={handleUserDataChange}
              required={true}
            />
            <InputField
              type="text"
              name="zip_code"
              label="Kodi postar"
              value={userData.zip_code}
              placeholder="Shkruani kodin postar"
              onChange={handleUserDataChange}
              required={true}
              error={errors.zip_code ? [errors.zip_code] : []}
            />
            <BankInfo
              bankNumber={userData.bank_account_number}
              bankId={userData.bank_id}
              onChange={handleUserDataChange}
              error={{
                bank_account_number: errors.bank_account_number
                  ? errors.bank_account_number
                  : null,
                bank_name: errors.bank_name ? errors.bank_name : null,
              }}
            />
            <div className="text-sm text-red-600 font-medium">
              {errors.terms_of_conditions}
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="terms"
                className="w-4 h-4 accent-primary border text-blue-600 border-gray-300 rounded-xl"
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
              />
              <label htmlFor="terms" className="text-sm leading-3">
                Pajtohem me{" "}
                <span
                  className="text-primary font-semibold hover:underline cursor-pointer"
                  onClick={() => setIsTermsOfConditionsOpen(true)}
                >
                  kushtet e përdorimit
                </span>
              </label>
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 my-4">
            <SubmitButton value="Regjistrohu" loading={isLoading} />
            {/* <div className="w-full bg-white text-gray-700 rounded-md py-[6px] px-4 flex gap-2 items-center justify-center border border-gray-300">
              <FcGoogle size={26} />
              <span className="font-medium">Hyr me Google</span>
            </div> */}
          </div>
        </form>
        <div className="text-center text-sm text-gray-600 mt-8">
          Keni një llogari?{" "}
          <Link to="/login">
            <span className="font-semibold text-primary hover:underline">
              Kyqu
            </span>
          </Link>
        </div>
      </div>
    </AuthWrapper>
  );
}
