import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`,
  }),
  endpoints: (build) => ({
    subscribe: build.mutation({
      query(data) {
        return {
          url: "/notifications/subscribe",
          method: "POST",
          data: data,
        };
      },
    }),

    userNotifications: build.query({
      query: () => ({
        url: "/users/notifications",
        method: "GET",
      }),
    }),

    userUnreadNotifications: build.query({
      query: () => ({
        url: "/users/unread_notifications",
        method: "GET",
      }),
    }),

    notificationtDetails: build.query({
      query: (id) => {
        return {
          url: `/users/notifications/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Notification"],
    }),
  }),
});

export const {
  useSubscribeMutation,
  useUserNotificationsQuery,
  useUserUnreadNotificationsQuery,
  useNotificationtDetailsQuery,
  useLazyUserNotificationsQuery,
} = notificationsApi;
