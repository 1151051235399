// import CircularProgress from "./CircularProgress";

// const TierProgress = ({ receiptCount, tiers }) => {
//   let currentTier = tiers[0];
//   let nextTier = null;

//   for (let i = 0; i < tiers.length; i++) {
//     if (receiptCount < tiers[i].required_receipts) {
//       currentTier = tiers[i];
//       nextTier = tiers[i + 1] || null;
//       break;
//     }
//   }

//   const prevTierRequirement =
//     tiers.find((t) => t.tier_level === currentTier.tier_level - 1)
//       ?.required_receipts || 0;
//   const progressPercentage = Math.min(
//     100,
//     ((receiptCount - prevTierRequirement) /
//       (currentTier.required_receipts - prevTierRequirement)) *
//       100
//   );

//   return (
//     <div className="p-4 border rounded-lg shadow-md w-full max-w-md">
//       <h2 className="text-lg font-bold">Niveli {currentTier.tier_level}</h2>
//       <p className="text-sm text-gray-600p py-2">
//         Progres: {receiptCount}/{currentTier.required_receipts} fatura
//       </p>
//       <CircularProgress percentage={progressPercentage} />
//       {nextTier && (
//         <p className="text-sm text-gray-500 mt-2">
//           Next Tier: {nextTier.tier_level} (Needs {nextTier.required_receipts}{" "}
//           receipts)
//         </p>
//       )}
//     </div>
//   );
// };

// export default TierProgress;

import CircularProgress from "./CircularProgress";

const TierProgress = ({ receiptCount, tiers }) => {
  if (!tiers || tiers.length === 0) {
    return (
      <div className="p-5 w-full rounded-2xl shadow-lg border border-gray-300 bg-white text-center">
        <h2 className="text-xl font-semibold text-gray-900">Progresi</h2>
        <p className="text-md text-gray-700 py-2">
          Loja nuk ka filluar ende! Nuk ka progres për përdoruesin.
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-between gap-6 sm:flex-row">
      {tiers?.map((tier, index) => {
        const prevTierRequirement =
          index === 0 ? 0 : tiers[index - 1].required_receipts;

        let progressPercentage = 0;
        let displayedReceipts = 0;

        if (receiptCount >= tier.required_receipts) {
          progressPercentage = 100;
          displayedReceipts = tier.required_receipts;
        } else if (receiptCount >= prevTierRequirement) {
          progressPercentage = Math.min(
            100,
            ((receiptCount - prevTierRequirement) /
              (tier.required_receipts - prevTierRequirement)) *
              100
          );
          displayedReceipts = receiptCount;
        } else {
          displayedReceipts = 0;
        }

        return (
          <div
            key={tier.tier_level}
            className={`p-5 w-full rounded-2xl shadow-lg transition-all duration-300 ${
              receiptCount >= prevTierRequirement
                ? "border border-blue-600 bg-gradient-to-r from-blue-100 to-blue-50 text-white"
                : "border border-gray-300 bg-white"
            }`}
          >
            <h2 className="text-xl font-semibold text-gray-900">
              Niveli {tier.tier_level} -{" "}
              <span className="text-primary">{tier.winning_amount}€</span>
            </h2>
            <p className="text-md text-gray-700 py-2">
              Progres:{" "}
              <span className="font-medium text-gray-900">
                {displayedReceipts}/{tier.required_receipts}
              </span>{" "}
              fatura
            </p>
            {receiptCount >= prevTierRequirement && (
              <div className="flex justify-center mt-4">
                <CircularProgress percentage={progressPercentage} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TierProgress;
