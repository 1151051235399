const StatsCard = ({ value, label, icon }) => {
  return (
    <div className="flex-1 min-w-[160px] md:min-w-[200px] p-4 md:p-6 flex flex-col bg-white rounded-xl shadow-lg transition-all duration-300">
      <div className="flex justify-between items-center">
        <p className="text-lg md:text-xl font-semibold text-gray-900">
          {value}
        </p>
        <div className="border border-gray-300 shadow-md rounded-xl p-2 bg-white flex items-center justify-center">
          <img src={icon} alt="icon" className="w-6 h-6 md:w-7 md:h-7" />
        </div>
      </div>
      <p className="text-gray-500 text-sm md:text-base mt-2">{label}</p>
    </div>
  );
};

export default StatsCard;
