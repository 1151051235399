import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const bankApi = createApi({
    reducerPath: 'bankApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`,
    }),
    endpoints: (build) => ({
        getBanks: build.query({
            query() {
                return {
                    url: `/banks`,
                    method: 'GET',
                }
            },
            providesTags: ['Banks']
        }),
    }),
});

export const {
    useGetBanksQuery,
} = bankApi;