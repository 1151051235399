import React, { useState } from "react";
import SideBar from "../components/SideBar/SideBar";
import NavBar from "../components/NavBar/NavBar";
import StatsCard from "../components/Statistics/StatsCard";
import ChartSection from "../components/Statistics/ChartSection";
import {
  useReceiptProgressQuery,
  useUserReceiptsDashboardQuery,
  useUserReceiptsGraphQuery,
} from "../store/services/receiptServices";
import TierProgress from "../components/CircularProgress/TierProgress";

const Statistics = () => {
  const [selected, setSelected] = useState("Të dorëzuara");
  const items = ["Të dorëzuara", "Të aprovuara", "Të refuzuara"];

  const { data: receiptsData } = useUserReceiptsDashboardQuery();
  const { data } = useReceiptProgressQuery();

  const statusParam =
    selected === "Të dorëzuara"
      ? "submitted"
      : selected === "Të aprovuara"
      ? "approved"
      : selected === "Të refuzuara"
      ? "rejected"
      : null;

  const { data: graphData } = useUserReceiptsGraphQuery({
    status: statusParam,
  });

  // const groupByMonth = (receipts) => {
  //   let groupedData = {};

  //   receipts.forEach(({ created_at }) => {
  //     const date = new Date(created_at);
  //     const month = date.toLocaleString("default", {
  //       month: "short",
  //       year: "numeric",
  //     });

  //     if (!groupedData[month]) {
  //       groupedData[month] = { name: month, uv: 0, date };
  //     }
  //     groupedData[month].uv += 1;
  //   });

  //   return Object.values(groupedData).sort((a, b) => a.date - b.date);
  // };

  const groupByMonthOrDay = (receipts) => {
    let groupedData = {};
    let uniqueMonths = new Set();

    receipts.forEach(({ created_at }) => {
      const date = new Date(created_at);
      const month = date.toLocaleString("default", {
        month: "short",
        year: "numeric",
      });
      const day = date.getDate();
      const dayLabel = `${day} ${month}`;

      uniqueMonths.add(month);

      if (uniqueMonths.size === 1) {
        if (!groupedData[dayLabel]) {
          groupedData[dayLabel] = { name: dayLabel, uv: 0, date };
        }
        groupedData[dayLabel].uv += 1;
      } else {
        if (!groupedData[month]) {
          groupedData[month] = { name: month, uv: 0, date };
        }
        groupedData[month].uv += 1;
      }
    });

    return Object.values(groupedData).sort((a, b) => a.date - b.date);
  };

  const statisticsData = graphData ? groupByMonthOrDay(graphData) : [];

  const statsData = [
    {
      value: receiptsData?.total_receipts ?? 0,
      label: "Totali i faturave",
      icon: "submit.png",
    },
    {
      value: receiptsData?.total_submitted_receipts ?? 0,
      label: "Faturat e dorëzuara",
      icon: "submit.png",
    },
    {
      value: receiptsData?.total_approved_receipts ?? 0,
      label: "Faturat e aprovuara",
      icon: "approved.png",
    },
    {
      value: receiptsData?.total_rejected_receipts ?? 0,
      label: "Faturat e refuzuara",
      icon: "approved.png",
    },
  ];

  const userProgress = data?.[0] || {};

  return (
    <div className="flex flex-col md:flex-row">
      <SideBar />
      <div className="flex-1 p-4 md:ml-64">
        <NavBar />
        <div className="p-4">
          <div className="border rounded-xl border-lightBlue shadow-sm flex flex-wrap justify-between mt-5 gap-5 md:gap-0">
            {statsData.map((item, index) => (
              <StatsCard
                key={index}
                value={item.value}
                label={item.label}
                icon={item.icon}
              />
            ))}
          </div>

          <div className="flex flex-col items-center space-y-6 p-6">
            <TierProgress
              receiptCount={userProgress.receipt_count}
              tiers={userProgress.tiers}
            />
          </div>
          <ChartSection
            items={items}
            selected={selected}
            setSelected={setSelected}
            data={statisticsData}
          />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
