import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const ChartSection = ({ items, selected, setSelected, data }) => {
  const hasData = data.length > 0;
  const placeholderData = [
    { name: "Jan", uv: 0 },
    { name: "Feb", uv: 0 },
    { name: "Mar", uv: 0 },
    { name: "Apr", uv: 0 },
    { name: "May", uv: 0 },
    { name: "Jun", uv: 0 },
    { name: "Jul", uv: 0 },
    { name: "Aug", uv: 0 },
    { name: "Sep", uv: 0 },
    { name: "Oct", uv: 0 },
    { name: "Nov", uv: 0 },
    { name: "Dec", uv: 0 },
  ];

  return (
    <div className="flex-1 p-6 flex-col border rounded-xl border-lightBlue mt-4 hidden sm:block">
      <div className="flex justify-between items-center mb-9">
        <div className="flex gap-5">
          {items.map((item) => (
            <h3
              key={item}
              className={`text-lg font-medium cursor-pointer px-4 py-2 border rounded-xl ${
                selected === item
                  ? "text-primary bg-lightBlue border-primary"
                  : "text-babyBlue bg-white border-lightBlue"
              }`}
              onClick={() => setSelected(item)}
            >
              {item}
            </h3>
          ))}
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={hasData ? data : placeholderData}
          syncId="anyId"
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="uv" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartSection;
