import React, { useState } from "react";
import SideBar from "../components/SideBar/SideBar";
import NavBar from "../components/NavBar/NavBar";
import Pagination from "../components/Pagination/Pagination";
import { useUserNotificationsQuery } from "../store/services/notificationServices";
import NotificationTable from "../components/NotificationTable.js/NotificationTable";
import NotificationBorder from "../components/NotificationBorder/NotificationBorder";

const Notifications = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(10);

  const {
    data: notificationsData,
    isLoading,
    isError,
  } = useUserNotificationsQuery();

  const notifications = Array.isArray(notificationsData)
    ? notificationsData
    : [];

  const totalPages = Math.ceil(notifications.length / notificationsPerPage);

  const indexOfLastNotification = currentPage * notificationsPerPage;

  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;

  const currentNotifications = notifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <SideBar />
      <div className="p-4 sm:ml-64">
        <NavBar />

        {isLoading ? (
          <div className="flex flex-col items-center justify-center min-h-screen">
            <p className="text-lg">Loading notifications...</p>
          </div>
        ) : isError ? (
          <div className="flex flex-col items-center justify-center min-h-screen">
            <p className="text-lg text-red-500">Error loading notifications.</p>
          </div>
        ) : notifications.length === 0 ? (
          <div className="flex flex-col items-center justify-center min-h-screen">
            <img
              src="/emptyReceipe.png"
              alt="No Data"
              className="w-32 h-32 sm:w-48 sm:h-48"
            />
            <p className="text-lg text-center mt-4">
              Nuk keni njoftime për t'i shfaqur!
            </p>
          </div>
        ) : (
          <>
            <div className="flex flex-col justify-center">
              <NotificationTable
                data={currentNotifications}
                title="Njoftimet"
              />

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />

              <NotificationBorder
                data={currentNotifications}
                title="Njoftimet"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;
