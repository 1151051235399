import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar/SideBar";
import NavBar from "../components/NavBar/NavBar";
import CustomDropdown from "../components/Dropdown/Dropdown";
import { useGetOwnAccountQuery } from "../store/services/profileServices";
import {
  useComplaintTypesQuery,
  useCreateComplaintMutation,
} from "../store/services/complaintsService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonNew from "../components/Button/ButtonNew";

const FeedBackBox = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [feedbackData, setFeedbackData] = useState({
    title: "",
    type_id: "",
    body: "",
  });

  const { data: complaintTypes } = useComplaintTypesQuery();
  const [createComplaint] = useCreateComplaintMutation();

  const { data: userData } = useGetOwnAccountQuery();
  const options = complaintTypes
    ? complaintTypes.map((type) => ({
        label: type.name.charAt(0).toUpperCase() + type.name.slice(1),
        value: type.id,
      }))
    : [];

  const userId = userData?.id;

  useEffect(() => {
    setFeedbackData((prev) => ({ ...prev, type_id: selectedValue }));
  }, [selectedValue]);

  const handleChange = (e) => {
    setFeedbackData({ ...feedbackData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    console.log("test");
    e.preventDefault();

    if (!selectedValue) {
      toast.error("Ju lutemi, zgjidhni një lloj ankese!");
      return;
    }

    const formData = {
      user_id: userId,
      type_id: selectedValue,
      title: feedbackData.title,
      body: feedbackData.body,
    };

    try {
      await createComplaint(formData).unwrap();
      toast.success("Ankesa u dergua me sukses!");
      setFeedbackData({ title: "", type_id: "", body: "" });
      setSelectedValue("");
    } catch (error) {
      toast.error("Diqka shkoi keq gjate dergimit te ankeses!");
    }
  };

  return (
    <div>
      <SideBar />
      <div className="p-4 sm:ml-64">
        <NavBar />
        <div className="p-4 flex justify-center items-center">
          <div className="w-[760px] flex flex-col gap-8 p-4">
            <h2 className="text-lg font-medium">Kutia e ankesave</h2>
            <ToastContainer position="top-right" autoClose={3000} />
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="grid sm:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="title" className="text-sm font-medium">
                    Titulli
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    className="w-full border shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
                    placeholder="Shkruaj titullin"
                    value={feedbackData.title}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Lloji i ankesës
                  </label>

                  <CustomDropdown
                    options={options}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    buttonClassName="w-full border shadow-sm flex text-start rounded-md py-[10px] px-4 placeholder-gray-500"
                    dropdownClassName="absolute bg-white border rounded-md mt-1 w-full z-10"
                    customStyles="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Komenti
                </label>
                <textarea
                  className="w-full mt-1 p-2 border rounded-md focus:ring-primary focus:border-primary"
                  placeholder="Shëno komentin këtu..."
                  rows="4"
                  name="body"
                  value={feedbackData.body}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <div className="grid sm:grid-cols-2 gap-4">
                <ButtonNew
                  text="Anulo"
                  color="bg-lightGrey"
                  textStyles="text-black"
                />
                <ButtonNew
                  text="Vazhdo"
                  type="submit"
                  color="bg-primary"
                  textStyles="text-white"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedBackBox;
