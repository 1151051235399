export const PhoneNumberInput = ({ prefix, dialling, phone1, phone2, onChange, error }) => {
    return (
        <div className="flex flex-col gap-1">
            <label htmlFor="phone_prefix" className="text-sm text-[#344054] font-medium">Numri i telefonit</label>
            <div className="flex items-center gap-2">
                <div
                    name="phone_prefix"
                    className="w-1/4 border border-gray-300 shadow-sm rounded-md py-[10px] px-2 placeholder-gray-500"
                    disabled
                >
                    <div className="flex justify-center gap-2 items-center">
                        <img src="/kosovo_flag.ico" className="w-4" />
                        <div>{prefix}</div>
                    </div>
                </div>

                <input
                    name="phone_dialling_code"
                    type="number"
                    className="w-1/6 text-center border border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
                    placeholder="44"
                    value={dialling}
                    onChange={(e) => {
                        if (e.target.value.length > 2) {
                            return;
                        }
                        onChange(e)
                    }}
                />
                <input
                    name="phone_number_1"
                    type="number"
                    className="w-1/4 text-center border border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
                    placeholder="000"
                    maxLength={3}
                    value={phone1}
                    onChange={(e) => {
                        if (e.target.value.length > 3) {
                            return;
                        }
                        onChange(e)
                    }}
                />
                <input
                    name="phone_number_2"
                    type="number"
                    className="w-1/4 border text-center border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
                    placeholder="000"
                    maxLength={3}
                    value={phone2}
                    onChange={(e) => {
                        if (e.target.value.length > 3) {
                            return;
                        }
                        onChange(e)
                    }}
                />
            </div>
            {error?.length > 0 && (
                error.map((error, index) => (
                    <div key={index} className="text-sm text-red-600 font-medium">{error}</div>
                ))
            )}
        </div>
    )
}