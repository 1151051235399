import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LogoHeader from "../components/auth/LogoHeader";
import SubmitButton from "../components/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import AuthWrapper from "../components/auth/AuthWrapper";
import { useForgotPasswordMutation } from "../store/services/authServices";
import { useDispatch } from "react-redux";
import { forgetPasswordActions } from "../store/forgetPasswordSlice";

export default function ForgotPassword() {
  const [forgotPassword] = useForgotPasswordMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email i pavlefshëm")
      .required("Email është i detyrueshëm"),
  });

  const handleFormSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      const response = await forgotPassword({ email: values.email }).unwrap();
      if (response) {
        console.log({ response });
        dispatch(forgetPasswordActions.addEmail(values.email));
        navigate("/verify");
      }
    } catch (error) {
      setFieldError("email", "Nuk ekziston llogari me këtë email");
      console.error("Couldn't access forgot password");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AuthWrapper>
      <LogoHeader />
      <div className="text-center">
        <h1 className="text-[30px] font-semibold">Riktheni fjalëkalimin</h1>
        <h3 className="text-gray-600">Shkruani emailin tuaj</h3>
      </div>
      <div className="my-4 mt-6">
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col">
                  <label htmlFor="email" className="font-medium">
                    Email
                  </label>
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Shkruani emailin tuaj"
                    className="border border-gray-300 rounded-md p-2 mt-1"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-600 text-sm mt-1"
                  />
                </div>
                <SubmitButton value="Dërgo" loading={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="text-center text-sm text-gray-600 mt-8">
        A e mban mend fjalëkalimin?{" "}
        <Link to="/login">
          <span className="font-semibold text-primary hover:underline">
            Kyqu
          </span>
        </Link>
      </div>
    </AuthWrapper>
  );
}
