import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa6";

const messages = [
  "Me ane te butonit Ngarko faturen, ju mund te ngarkoni nje fature dhe te beheni pjese e lojes.",
  "Gjate skanimit duhet mbushur te gjitha fushat, perndryshe krijimi i fatures nuk do te ndodhe.",
  "Tek tabela do shfaqen te gjitha faturat qe ju skanoni.",
  "Faturat e aprovuara do te keni mundesi ti shikoni poashtu ne nje faqe veqmas.",
  "Profili juaj do te jete i editueshem, dhe do shfaqet tek SideBar-i si te gjitha faqet tjera.",
  "Tek pjesa e Njoftimeve do te njoftohi per te gjitha risite lidhje me ju sa i perket lojes. ",
  "Do te mund te shihni detajet e faturave dhe njoftimeve vetem duke klikuar mbi to ne tabele.",
  "Pjesa e statistikes do te shfaq te gjitha faturat dhe ne baze te nje grafi do te shihet qarte se sa kuponi jane krijuar.",
  "Eshte pjesa e kutise se ankeses, ku ju si user do te keni mundesi te dergoni ankesa ne lidhje me aplikacionin/webin.",
];

const ComplaintFormModal = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
  };

  return (
    <div className="p-6 bg-white rounded-2xl shadow-lg w-96 flex flex-col gap-4">
      <p className="text-base text-blue-900 font-medium text-center">
        {messages[currentIndex]}
      </p>

      <div className="flex justify-between items-center">
        <div className="flex gap-1">
          {messages.map((_, index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full transition-all duration-300 ${
                index === currentIndex ? "bg-blue-900 w-8" : "bg-gray-300"
              }`}
            />
          ))}
        </div>

        <button
          onClick={handleNext}
          className="bg-primary p-3 rounded-full flex items-center justify-center  transition-all duration-300"
        >
          <FaChevronRight size={13} color="white" />
        </button>
      </div>
    </div>
  );
};

export default ComplaintFormModal;
