export const BirthdateInput = ({ day, month, year, onChange, error }) => {

    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    const months = ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nentor', 'Dhjetor']
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 100 }, (_, i) => currentYear - 18 - i);

    return (
        <div className="flex flex-col gap-1">
            <label className="text-sm text-[#344054] font-medium">Data e lindjes</label>
            <div className="flex items-center gap-2">
                <select name="birth_day" className="w-1/3 border border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500" onChange={onChange} value={day}>
                    <option value={""} disabled>Dita</option>
                    {days.map((day, index) => (
                        <option key={index} value={day}>{day}</option>
                    ))}
                </select>
                <select name="birth_month" className="w-1/3 border border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500" onChange={onChange} value={month}>
                    <option value={""} disabled>Muaji</option>
                    {months.map((month, index) => (
                        <option key={index} value={index + 1}>{month}</option>
                    ))}
                </select>
                <select name="birth_year" className="w-1/3 border border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500" onChange={onChange} value={year}>
                    <option value={""} disabled>Viti</option>
                    {years.map((year, index) => (
                        <option key={index} value={year}>{year}</option>
                    ))}
                </select>
            </div>
            {error?.length > 0 && (
                error.map((error, index) => (
                    <div key={index} className="text-sm text-red-600 font-medium">{error}</div>
                ))
            )}
        </div>
    )
}